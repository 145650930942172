import React, { useEffect, useState } from "react";
import "./App.css";
import socket from "./socket";
import { getTranslation, SevUrl } from "./Utility";
import FailPage from "./FailPage";
const isLocalhost = window.location.hostname === "localhost";
const languageDisplay = {
  ko: "KOREAN",
  cn: "CHINESE",
  en: "ENGLISH",
  jp: "JAPANESE",
};

const getLangCookie = () => {
  const match = document.cookie.match(/(?:^|;\s*)lang=([^;]+)/);
  return match ? match[1] : "ko";
};

function App() {
  const [userId, setUserId] = useState("");
  const [userPw, setUserPw] = useState("");
  const [langDropdownOpen, setLangDropdownOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(getLangCookie());
  const [token, setToken] = useState("");
  const [Terms, setTerms] = useState(false);
  const [Fail, setFail] = useState(false);
  const paragraphs = getTranslation(selectedLanguage, "terms_text")
    .split("\n")
    .filter((line) => line.trim() !== "");

  function GoLobby() {
    const EndPoint = isLocalhost
      ? `http://localhost:3001/?token=${token}&list=414C4C&caseName=${
          SevUrl().caseName
        }&country=${selectedLanguage}`
      : `https://avata-client.pd-live-services.com/lobby/?token=${token}&list=414C4C&caseName=${
          SevUrl().caseName
        }&country=${selectedLanguage}`;

    window.location.href = EndPoint;
  }

  // 언어 변경 시 쿠키를 설정하고 선택된 언어를 업데이트하는 함수
  const changeLangCookie = (lang) => {
    document.cookie = `lang=${lang}; path=/`;
    setSelectedLanguage(lang);
    setLangDropdownOpen(false); // 언어 선택 후 드롭다운 닫기

    const url = new URL(window.location.href);
    url.searchParams.set("country", lang);
    window.history.replaceState(null, "", url.toString());
  };

  // 언어 드롭다운 토글 함수
  const toggleDropdown = () => {
    setLangDropdownOpen(!langDropdownOpen);
  };

  // 게스트 로그인 버튼 클릭 시 호출되는 함수
  const handleGuestLogin = () => {
    window.location.href = "/guestLogin";
  };

  // 로그인 폼 제출 처리 함수
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!userId || !userPw) return;
    socket.emit("LoginRequest", { id: userId, pw: userPw });
  };
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const country = searchParams.get("country");
    if (country) {
      document.cookie = `lang=${country}; path=/`;
      setSelectedLanguage(country);
    }

    if (!socket.connected) {
      socket.connect();
    }
    socket.on("connect", () => {
      console.log("connect");
    });
    socket.on("LoginFail", () => {
      console.log("LoginFail");
      setFail(true);
    });
    socket.on("LoginSuccess", (data) => {
      console.log("succecss", data);
      setToken(data.mb_token);
      setTerms(true);
    });
    return () => {
      socket.off("connect");
      socket.off("LoginFail");
      socket.off("LoginSuccess");
    };
  }, [selectedLanguage]);

  return (
    <div className="overlay">
      {Fail ? (
        <FailPage
          onResetFail={() => setFail(false)}
          selectedLanguage={selectedLanguage}
        />
      ) : !Terms ? (
        <div className="form-container">
          <div className="img-box">
            <img src="./icon.png" alt="Icon" />
          </div>
          <div className="language_class">
            <button type="button" id="btn_language" onClick={toggleDropdown}>
              <div id="select_lang">{languageDisplay[selectedLanguage]}</div>
              <span></span>
            </button>
            <div
              className={`select ${langDropdownOpen ? "active" : ""}`}
              id="select"
            >
              <div onClick={() => changeLangCookie("ko")}>
                <img src="./language_ko.jpg" alt="Korean" /> KOREAN
              </div>
              <div onClick={() => changeLangCookie("cn")}>
                <img src="./language_ch.jpg" alt="Chinese" /> CHINESE
              </div>
              <div onClick={() => changeLangCookie("en")}>
                <img src="./language_en.jpg" alt="English" /> ENGLISH
              </div>
              <div onClick={() => changeLangCookie("jp")}>
                <img src="./language_jp.jpg" alt="Japanese" /> JAPANESE
              </div>
            </div>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="input-field">
              <input
                type="text"
                placeholder={getTranslation(selectedLanguage, "id")}
                name="user_id"
                id="user_id"
                autoComplete="off"
                value={userId}
                onChange={(e) => setUserId(e.target.value)}
              />
            </div>
            <div className="input-field">
              <input
                type="password"
                placeholder={getTranslation(selectedLanguage, "pw")}
                name="user_pw"
                id="user_pw"
                autoComplete="off"
                value={userPw}
                onChange={(e) => setUserPw(e.target.value)}
              />
            </div>
            <div className="input-field">
              <input
                type="submit"
                value={getTranslation(selectedLanguage, "login")}
                id="login_submit"
              />
            </div>
          </form>
          {false && (
            <div className="input-field">
              <button id="login_guest" onClick={handleGuestLogin}>
                {getTranslation(selectedLanguage, "guest_login")}
              </button>
            </div>
          )}
        </div>
      ) : (
        <div className="form-container2">
          <div id="agree_title">
            {getTranslation(selectedLanguage, "terms_title")}
          </div>
          <div className="form-container2_box">
            <div className="i_list" id="agree_txt">
              {paragraphs.map((para, idx) => (
                <p key={idx}>{para}</p>
              ))}
            </div>
          </div>
          <div className="i_btn">
            <div className="input-field">
              <button onClick={() => setTerms(false)}>
                <span id="agree_cancel">
                  {getTranslation(selectedLanguage, "cancel")}
                </span>
              </button>
            </div>
            <div className="input-field">
              <button onClick={() => GoLobby()}>
                <span id="agree_ok">
                  {getTranslation(selectedLanguage, "login")}
                </span>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
